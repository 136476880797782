import React, { useEffect, useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'

import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'

import { Contact } from 'components/organisms/Contact'
import { Gallery } from 'components/organisms/Gallery'
import { Breadcrumbs } from 'components/modules/Breadcrumbs'
import { Container } from 'components/atoms/Container'
import { Wrapper } from 'components/modules/Breadcrumbs/Breadcrumbs.styles'
import { H1Light } from 'components/atoms/Typography'
import { OptionButton } from 'components/atoms/Button'
import media from 'styles/media'
import { ImageCarusela } from 'components/organisms/Gallery/ImageCarusela'

const StyledContainer = styled(Container)`
  max-width: calc(1136px + 112px);

  ${Wrapper} {
    border: none;
    border-top: 1px solid ${({ theme }) => theme.colors.gray05};
  }

  ${media.sm.max} {
    padding: 0px;
  }
`

const GalleryHeading = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;

  ${media.sm.max} {
    margin-top: 59px;
    padding: 48px 24px;
    padding-right: 0px;
  }
`

const OptionsConatiner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  ${media.sm.max} {
    justify-content: flex-start;
    margin-right: auto;
  }
`

const StyledH1Light = styled(H1Light)`
  ${media.sm.max} {
    text-align: left;
    margin-right: auto;
  }
`

const GalleryPage: React.FC<PageProps<Queries.GaleriaPageQuery>> = ({
  data,
}) => {
  const PAGE = data.wpPage?.gallery
  const PAGE_SEO = data.wpPage?.seo!
  const POPUP = data.wpPage?.TypePage ?? null

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const ALL_IMAGES = PAGE.galleryImgsGallery?.map((image) => ({
    src: image?.galleryImgsGallerySingle?.localFile?.childImageSharp
      ?.gatsbyImageData!,
    alt: image?.galleryImgsGallerySingle?.altText!,
    category: image?.galleryImgsGalleryType!,
  }))!

  const CATEGORIES = Array.from(
    new Set(ALL_IMAGES.map((image) => image.category))
  ) || ['Wszystkie']

  const [selectedView, setSelectedView] = useState<string>(CATEGORIES[0])
  const [selectedImage, setSelectedImage] = React.useState<number>(0)
  const [showImageSlider, setShowImageSlider] = React.useState<boolean>(false)

  const handelShowSlider = () => {
    const newShowImageSliderState = !showImageSlider
    setShowImageSlider(newShowImageSliderState)

    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    searchParams.set('showImageSlider', newShowImageSliderState.toString())

    window.history.pushState(
      null,
      '',
      `${url.pathname}?${searchParams.toString()}`
    )
  }

  const handelSliderImageView = (index: number) => {
    setSelectedImage(index)
    handelShowSlider()
  }

  useEffect(() => {
    const handlePopstate = () => {
      const url = new URL(window.location.href)
      const searchParams = new URLSearchParams(url.search)
      const newShowImageSliderState =
        searchParams.get('showImageSlider') === 'true'

      setShowImageSlider(newShowImageSliderState)
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  return (
    <>
      {showImageSlider && (
        <ImageCarusela
          images={ALL_IMAGES.filter(
            (image) => image.category === selectedView
          ).map(({ src, alt }) => ({
            src,
            alt,
          }))}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          handelShowSlider={handelShowSlider}
        />
      )}

      <Layout
        popup={
          POPUP
            ? {
                image: {
                  src: POPUP?.popupImage?.localFile?.childImageSharp
                    ?.gatsbyImageData!,
                  alt: POPUP?.popupImage?.altText!,
                },
                active: POPUP?.popupActive!,
                activeMobile: POPUP?.popupActiveMobile!,
                delay: POPUP?.popupDelay!,
                link: POPUP?.popupLink!,
              }
            : undefined
        }
      >
        <Seo
          title={PAGE_SEO.title ?? ''}
          description={PAGE_SEO.metaDesc ?? ''}
          ogTitle={PAGE_SEO.opengraphTitle}
          ogDescription={PAGE_SEO.opengraphDescription}
          ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
          twitterTitle={PAGE_SEO.twitterTitle}
          twitterDescription={PAGE_SEO.twitterDescription}
          twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
        />

        <GalleryHeading margin={[91, 'auto', 0]} padding={[72, 0]}>
          <StyledH1Light
            as="h1"
            dangerouslySetInnerHTML={{ __html: PAGE.galleryHeroTitle! }}
          />
          <OptionsConatiner>
            {CATEGORIES.map((category) => (
              <OptionButton
                selected={selectedView === category}
                onClick={() => setSelectedView(category)}
              >
                {category}
              </OptionButton>
            ))}
          </OptionsConatiner>
        </GalleryHeading>

        <StyledContainer padding={[0, 56]} margin={[0, 'auto']}>
          <Breadcrumbs crumbs={[{ name: data.wpPage.title! }]} />
        </StyledContainer>

        <Gallery
          images={ALL_IMAGES.filter(
            (image) => image.category === selectedView
          ).map(({ src, alt }) => ({
            src,
            alt,
          }))}
          handelSlider={handelSliderImageView}
        />
        <Contact
          heading={PAGE.galleryFormsTitle!}
          formTitle={PAGE.galleryFormsFormsTitle!}
          image={{
            src: PAGE.galleryFormsImg?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE.galleryFormsImg?.altText!,
          }}
        />
      </Layout>
    </>
  )
}

export default GalleryPage

export const query = graphql`
  query GaleriaPage {
    wpPage(slug: { regex: "/galeria/" }) {
      TypePage {
        popupActive
        popupActiveMobile
        popupDelay
        popupLink
        popupImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
      }
      title
      gallery {
        galleryHeroTitle
        galleryFormsTitle
        galleryFormsFormsTitle
        galleryFormsImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
        galleryImgsGallery {
          galleryImgsGalleryType
          galleryImgsGallerySingle {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 900)
              }
            }
          }
        }
      }
      seo {
        ...WpSEO
      }
    }
  }
`
